import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import { projectFilesSelector } from '.';

const state = (store: Store) => store;

export const commitSelector = createSelector(state, (s) => s.commit);

export const reverseCommitsSelector = createSelector(state, (s) => {
  if (s.device.connectedDevice == null) return [];
  const cs = [...s.commit.commits];
  cs.reverse();
  return cs;
});

export const hasChangesSinceLastCommitSelector = createSelector(
  state,
  projectFilesSelector,
  (s, projectFiles) => {
    if (s.device.connectedDevice == null) return false;
    if (s.commit.commits.length === 0) {
      return true;
    }
    const lastCommit = s.commit.commits[s.commit.commits.length - 1];
    const files = projectFiles
      .filter((f) => !f.id.includes('lib-'))
      .map((f) => ({ id: f.id, content: f.newContent }));

    const fileMap: { [key: string]: { id: string; content: string } } = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const commitedFile of lastCommit.commitedFiles) {
      fileMap[commitedFile.fileId] = {
        id: commitedFile.fileId,
        content: commitedFile.fileContent,
      };
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      if (Object.keys(fileMap).includes(file.id)) {
        if (fileMap[file.id]?.content !== file.content) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  },
);
