import { createSelector } from 'reselect';

import Store from '@projectTypes/Store';
import { ProjectType } from '@projectTypes/Project';
import config from '@config';

const state = (store: Store) => store;

export const filesSelector = createSelector(state, (s) => s.file);

export const openFilesSelector = createSelector(state, (s) =>
  s.file.openIds.map((id) => s.file.files.find((f) => f.id === id)),
);

const IS_FILE_HIDDEN: Partial<Record<ProjectType, string[]>> = {
  [ProjectType.PYTHON]: ['test.json', 'config.json'],
};

export const fileStructureSelector = createSelector(state, (s) => {
  const project = s.project.projects.find(
    (p) => p.id === s.project.selectedProjectId,
  );
  let files = s.file.files.filter(
    (file) => file.projectId === s.project.selectedProjectId,
  );

  const newFileSystem = {};
  let currentId;
  let maxDepth;
  files.forEach((file) => {
    let currentFolder = newFileSystem;
    if (file.path) {
      const currentPath = [];
      file?.path.split('/').forEach((subfolder) => {
        if (!subfolder) {
          return;
        }
        currentPath.push(subfolder);
        if (!currentFolder[subfolder]) {
          files.forEach((el) => {
            if (
              el &&
              el.path === currentPath.join('/') &&
              el.name === '.keep'
            ) {
              currentId = el.id;
            }
          });
          currentFolder[subfolder] = {
            id: currentId,
            isFolder: true,
            name: subfolder,
            path: currentPath.join('/'),
          };
          maxDepth++;
        }
        currentFolder = currentFolder[subfolder];
      });
    }
    currentFolder[file.name] = file;
  });

  return { fileSystem: newFileSystem, maxDepth: maxDepth };
});

export const projectFilesSelector = createSelector(state, (s) => {
  const project = s.project.projects.find(
    (p) => p.id === s.project.selectedProjectId,
  );
  let files = s.file.files.filter(
    (file) => file.projectId === s.project.selectedProjectId,
  );
  // TODO This should be handled on the backend side of a project
  if (project?.type === ProjectType.PYTHON) {
    files = files.filter(
      (f) => f.name !== 'pipeline.yml' && f.name !== 'policies.yml',
    );
  }

  if (IS_FILE_HIDDEN[project?.type]) {
    files = files.filter(
      (f) => !IS_FILE_HIDDEN[project?.type].includes(f.name),
    );
  }

  if (
    project?.attributes.database &&
    !files.find((f) => f.path.includes('database'))
  ) {
    files.push({
      id: '-1',
      name: '',
      content: '',
      contentType: '',
      projectId: '-1',
      templateFileId: '-1',
      createdAt: new Date(),
      updatedAt: new Date(),
      newContent: '',
      isChanged: false,
      path: 'database',
    });
  }
  return files;
});

export const anyFileHasError = createSelector(
  state,
  projectFilesSelector,
  (s, projectFiles) => {
    return projectFiles.some((f) => f.hasError);
  },
);

export const displayedFileSelector = createSelector(state, (s) =>
  s.file.files.find((file) => file.id === s.file.displayedFileId),
);

export const displayedFileIdSelector = createSelector(
  state,
  (s) => s.file.displayedFileId,
);

export const jupyterStateSelector = createSelector(
  state,
  (s) => s.file.jupyterState,
);

export const anyFileHasChangesSelector = createSelector(
  projectFilesSelector,
  (p) => p.findIndex((f) => f.isChanged) !== -1,
);

export const isCurrentFileActionsPy = createSelector(
  displayedFileSelector,
  (file) => {
    return file && file.name === 'actions.py' && file.path === 'actions';
  },
);

export const inoProjectFileSelector = createSelector(
  projectFilesSelector,
  (p) => p.find((f) => f.name === config.mainArduinoFileName),
);

export const XMLProjectFileSelector = createSelector(
  projectFilesSelector,
  (p) => p.find((f) => f.name === config.mainXMLFileName),
);

export const HTMLProjectFileSelector = createSelector(
  projectFilesSelector,
  (p) => p.find((f) => f.name === config.mainHTMLFileName),
);

export const CSSProjectFileSelector = createSelector(
  projectFilesSelector,
  (p) => p.find((f) => f.name === config.mainCSSFileName),
);

export const JSProjectFileSelector = createSelector(projectFilesSelector, (p) =>
  p.find((f) => f.name === config.mainJSFileName),
);

export const renderHtmlSelector = createSelector(state, (s) => {
  const projectFiles = s.file.files.filter(
    (file) => file.projectId === s.project.selectedProjectId,
  );

  const srcHtml = projectFiles.find(
    (file) =>
      file.name === s.project.TargetFile &&
      file.projectId === s.project.selectedProjectId,
  );

  if (srcHtml) {
    const lines = srcHtml.newContent.split('\n').map((line) => {
      let quotes = 0;
      let match = line.match(/.*<link.*href="(.*)".*/);
      if (!match) {
        match = line.match(/.*<script.*src="(.*)".*/);
      }
      if (!match) {
        match = line.match(/.*<img.*src="(.*)".*>/);
        quotes = 2;
      }
      if (!match) {
        match = line.match(/.*<img.*src='(.*)'.*>/);
        quotes = 1;
      }

      if (match && match[0].includes('img')) {
        // find regex, split it by " or ' to get separate parts of the <img>,
        // we split the path by / and take the last name as the selected file
        match[1] = match[1].trim();

        if (quotes == 2) {
          match[1] = match[1].split('"')[0];
        } else {
          match[1] = match[1].split("'")[0];
        }

        match[1] = match[1].split('/').pop();
      }

      if (match && match[1]) {
        const file = projectFiles.find((f) => f.name === match[1]);
        if (file) {
          if (file.contentType === 'css') {
            line = '<style>' + file.newContent + '</style>';
          } else if (file.contentType === 'js') {
            line = '<script>' + file.newContent + '</script>';
          } else if (
            config.allowedImageUploadTypes.includes(file.contentType)
          ) {
            let replaceText;
            if (quotes == 2) {
              replaceText = line.match(/.*<img.*src="(.*)".*/)[1];
            } else {
              replaceText = line.match(/.*<img.*src='(.*)'.*/)[1];
            }
            replaceText = replaceText.split('"')[0];
            line = line.replace(replaceText, file.newContent);
          }
        }
      }

      return line;
    });
    const code = lines.join('\n');

    return { html: code, id: srcHtml.id };
  } else {
    return { html: '', id: '' };
  }
});
