import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Commit, { CommitStore } from '@projectTypes/Commit';
import commitService from '@services/CommitService';
import userActionService from '@services/UserActionService';
import actionNameCreator from '@helpers/actionNameCreator';
import Store from '@projectTypes/Store';
import { setNewFileContent } from '@slices/fileSlices';
import { setIsRightSidebarOpen } from './globalSlices';

const commitInitialState: CommitStore = {
  isLoading: false,
  commits: [],
  showDiff: false,
  diffIndex: 0,
  showCommitModal: false,
  diffWithWorkingDraft: false,
};

const anc = actionNameCreator('STAGE');

const commitSlices = createSlice({
  name: 'commit',
  initialState: commitInitialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCommits(state, action: PayloadAction<Commit[]>) {
      state.commits = action.payload;
    },
    setShowCommitDiff(state, action: PayloadAction<boolean>) {
      state.showDiff = action.payload;
      state.diffIndex = state.commits.length - 1;
    },
    incrementCommitDiffIndex(state) {
      state.diffIndex++;
    },
    decrementCommitDiffIndex(state) {
      state.diffIndex--;
    },
    setCommitDiffIndex(state, action: PayloadAction<number>) {
      state.diffIndex = action.payload;
    },
    setShowCommitModal(state, action: PayloadAction<boolean>) {
      state.showCommitModal = action.payload;
    },
    setDiffWithWorkingDraft(state, action: PayloadAction<boolean>) {
      state.diffWithWorkingDraft = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setCommits,
  setShowCommitDiff,
  incrementCommitDiffIndex,
  decrementCommitDiffIndex,
  setCommitDiffIndex,
  setShowCommitModal,
  setDiffWithWorkingDraft,
} = commitSlices.actions;
export default commitSlices.reducer;

export const createCommit = createAsyncThunk(
  anc('createCommit'),
  async (
    data: {
      data: { message: string; files: { id: string; content: string }[] };
      projectId: string;
      userId: string;
      deviceId: string;
    },
    { dispatch },
  ) => {
    dispatch(setIsLoading(true));
    try {
      const commit = await commitService.createCommit(
        data.data,
        data.projectId,
        data.userId,
        data.deviceId,
      );
      userActionService.trackAction('editor_commit_project', {
        commitId: commit.id,
        projectId: data.projectId,
        deviceId: data.deviceId,
      });
      dispatch(getCommits(data));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const loadCommit = createAsyncThunk<void, number, { state: Store }>(
  anc('loadCommit'),
  async (index: number, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    try {
      const commit = getState().commit.commits[index];
      commit.commitedFiles.forEach((file) => {
        dispatch(
          setNewFileContent({
            fileId: file.fileId,
            newContent: file.fileContent,
          }),
        );
      });
      dispatch(setIsRightSidebarOpen(false));
      dispatch(setShowCommitDiff(false));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);

export const getCommits = createAsyncThunk(
  anc('getCommits'),
  async (
    data: { projectId: string; userId: string; deviceId: string | undefined },
    { dispatch },
  ) => {
    dispatch(setIsLoading(true));
    try {
      const commits = await commitService.getCommits(
        data.projectId,
        data.userId,
        data.deviceId,
      );
      dispatch(setCommits(commits));
      dispatch(setCommitDiffIndex(commits.length - 1));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      throw e;
    }
  },
);
